import { Sector } from '@register'
import type { IconName } from '#build/icons'

export type LeaseSectorObject = { label: string; icon: IconName }

export const leaseSectorObject: Record<Sector, LeaseSectorObject> = {
  [Sector.CHILDCARE]: {
    label: 'Childcare',
    icon: 'care',
  },
  [Sector.FUEL]: {
    label: 'Fuel',
    icon: 'fuel',
  },
  [Sector.INDUSTRIAL]: {
    label: 'Industrial',
    icon: 'factory',
  },
  [Sector.OFFICE]: {
    label: 'Office',
    icon: 'building',
  },
  [Sector.RETAIL]: {
    label: 'Retail',
    icon: 'basket',
  },
  [Sector.UNKNOWN]: {
    label: 'Sector Not Specified',
    icon: 'question-select',
  },
}

export const leaseSectorOptions = Object.entries(leaseSectorObject).map(
  ([value, { label, icon }]) => ({ label, value, icon }),
)

export const leaseSectorReportOptions = [
  { label: 'All', value: null },
  ...leaseSectorOptions.filter(({ value }) => value !== Sector.UNKNOWN),
]
